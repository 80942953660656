"use strict";

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard")["default"];
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.savedChildren = exports["default"] = void 0;
var _createChildWapp = _interopRequireWildcard(require("default-app/dist/common/createChildWapp"));
var savedChildren = _createChildWapp.savedChildren;
exports.savedChildren = savedChildren;
var _default = _createChildWapp["default"];
exports["default"] = _default;